<template>
  <div class="sign-in">
    <h1 class="sign-in__title">
      <img src="@/assets/logo.svg" alt="logo" />
      Вход
    </h1>

    <p class="error" v-if="error">
      Вам необходимо перейти сюда авторизованным из любого из наших сервисов,
      или напишите нам на почту
      <a href="mailto:hello@rc.company">hello@rc.company</a>
    </p>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import { BASE_URL } from "@/main";

export default {
  name: "SignIn",
  data() {
    return {
      error: true
    };
  },

  methods: {
    ...mapActions({
      checkSource: "source/checkSource"
    }),

    auth() {
      if (this.$route.query.source) {
        this.error = false;

        this.checkSource(this.$route.query.source)
          .then(response => {
            localStorage.setItem("source", response.data.id);
            window.location.href =
              // "https://passport.royalclub.cash/api/oauth2/authorize?client_id=fdd0dd47-2b65-4f71-9065-d2f76f4e7068";
              // "https://auth-hub-staging.stage.rccore.net/api/oauth2/authorize?client_id=fdd0dd47-2b65-4f71-9065-d2f76f4e7068";
              `${BASE_URL}/oauth/rcpassport/redirect`;
          })
          .catch(() => {
            this.$router.push("/sign-in");
            localStorage.clear();
          });
      } else {
        this.error = true;
        localStorage.clear();
      }
    }
  },

  created() {
    this.auth();
  }
};
</script>

<style lang="scss" scoped>
.sign-in {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 20px;

  .error {
    max-width: 500px;
    text-align: center;
  }

  &__title {
    font-weight: 500;
    font-size: 36px;
    line-height: 43px;
    margin-bottom: 46px;
    display: flex;
    flex-direction: column;
    align-items: center;

    > img {
      width: 56px;
      height: 56px;
      margin-bottom: 8px;
    }
  }

  &__form {
    display: flex;
    flex-direction: column;
    width: 310px;

    label {
      font-size: 14px;
      line-height: 17px;
      color: rgba(34, 17, 34, 0.7);
      transition: all 0.2s linear;
    }

    input {
      width: 100%;
      height: 40px;
      border: none;
      border-bottom: 2px solid #ebecf2;
      margin-bottom: 20px;
      outline: none;
      padding: 0 8px;
    }
  }

  .form {
    &__item {
      position: relative;

      &--not-empty {
        label {
          top: -12px !important;
          font-size: 10px !important;
        }
      }

      label {
        position: absolute;
        top: 10px;
        left: 8px;
      }

      input:focus ~ label,
      input:not(:focus):valid ~ label {
        top: -12px;
        font-size: 10px;
      }
    }
  }

  &__btn {
    width: 310px;
    background-color: $main;
    color: $label-four;
    font-size: 14px;
    line-height: 17px;
    padding: 12px;
    border-radius: 8px;
    border: none;
    outline: none;
    cursor: pointer;
    margin-top: 12px;
  }
}
</style>
