<template>
  <div class="sign-in">
    <h1 class="sign-in__title">
      <img src="@/assets/logo.svg" alt="logo" />
      Вход
    </h1>

    <form class="sign-in__form form" @submit.prevent="signIn" id="form">
      <div
        class="form__item"
        :class="params.email ? 'form__item--not-empty' : ''"
      >
        <input v-model="params.email" id="login" type="text" required />

        <label for="login">Почта/Телефон</label>
      </div>

      <div
        class="form__item"
        :class="params.password ? 'form__item--not-empty' : ''"
      >
        <input
          v-model="params.password"
          id="password"
          type="password"
          required
        />

        <label for="password">Пароль</label>
      </div>
    </form>

    <button class="sign-in__btn" form="form" value="submit" type="submit">
      Войти
    </button>
  </div>
</template>

<script>
import { mapActions } from "vuex";

export default {
  name: "SignIn",
  data() {
    return {
      params: {
        email: "",
        password: ""
      }
    };
  },

  methods: {
    ...mapActions({
      auth: "oauth/auth",
      login: "oauth/login"
    }),

    signIn() {
      this.auth().then(() => {
        this.login(this.params).then(() => {
          window.location.href = "/";
        });
      });
    }
  }
};
</script>

<style lang="scss" scoped>
.sign-in {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  &__title {
    font-weight: 500;
    font-size: 36px;
    line-height: 43px;
    margin-bottom: 46px;
    display: flex;
    flex-direction: column;
    align-items: center;

    > img {
      margin-bottom: 8px;
      width: 56px;
      height: 56px;
    }
  }

  &__form {
    display: flex;
    flex-direction: column;
    width: 310px;

    label {
      font-size: 14px;
      line-height: 17px;
      color: rgba(34, 17, 34, 0.7);
      transition: all 0.2s linear;
    }

    input {
      width: 100%;
      height: 40px;
      border: none;
      border-bottom: 2px solid #ebecf2;
      margin-bottom: 20px;
      outline: none;
      padding: 0 8px;
    }
  }

  .form {
    &__item {
      position: relative;

      &--not-empty {
        label {
          top: -12px !important;
          font-size: 10px !important;
        }
      }

      label {
        position: absolute;
        top: 10px;
        left: 8px;
      }

      input:focus ~ label,
      input:not(:focus):valid ~ label {
        top: -12px;
        font-size: 10px;
      }
    }
  }

  &__btn {
    width: 310px;
    background-color: $main;
    color: $label-four;
    font-size: 14px;
    line-height: 17px;
    padding: 12px;
    border-radius: 8px;
    border: none;
    outline: none;
    cursor: pointer;
    margin-top: 12px;
  }
}
</style>
